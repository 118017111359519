import { Component } from '@angular/core'

@Component({
  selector: 'aku-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent {

}
