import {Component, ViewChild} from '@angular/core'

@Component({
  selector: 'aku-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  @ViewChild('tabGroup') tabGroup: any

  public swipeLeft() {
    this.tabGroup.selectedIndex += 1 // Switch tab to the right
  }

  public swipeRight() {
    this.tabGroup.selectedIndex -= 1 // Switch tab to the left
  }
}
