<div matDialogTitle>Signeringsbevis</div>
<div class="proof-holder" matDialogContent>
    <mat-form-field style="padding-top: 6px; padding-right: 8px">
        <mat-label>Tidpunkt:</mat-label>
        <input class="line-height" matInput readonly
               value="{{data.timeStamp | date: 'YYYY-MM-dd, HH:mm': undefined : 'fr'}}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px; padding-right: 8px">
        <mat-label>Fotograf:</mat-label>
        <input class="line-height" matInput readonly
               value="{{ data.photographerSub | slice:0:8 }}-{{ data.photographerSub | slice:8 }}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px; padding-right: 8px">
        <mat-label>Modell:</mat-label>
        <input class="line-height" matInput readonly
               value="{{ data.photoSubjectSub | slice:0:8 }}-{{ data.photoSubjectSub | slice:8 }}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px; padding-right: 8px">
        <mat-label>Beskrivning:</mat-label>
        <input class="line-height" matInput readonly value="{{data.description}}">
    </mat-form-field>
    <mat-form-field style="padding-top: 6px; padding-right: 8px">
        <mat-label>SigneringsID:</mat-label>
        <input class="line-height" matInput readonly value="{{data.itemId}}">
    </mat-form-field>
    <div>
        <img alt="photo" src="{{data.photo}}" style="max-width: 200px; padding-top: 5px; padding-right: 8px">
    </div>
    <div class="buttons-holder" matDialogActions>
        <button [mat-dialog-close]="null" color="primary" mat-raised-button>Stäng</button>
        <div *ngIf="removeConsent" style="padding-left: 10px">
            <button (click)="openDialog(data.itemId)" color="red" mat-raised-button>Ta bort
                medgivande
            </button>
        </div>
    </div>
</div>

