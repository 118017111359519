import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {LOGIN_ROUTE_PATH} from "./application/data-types"
import {LoginComponent} from "./1-login/login.component"
import {authGuard} from "./application/auth.guard"
import {AdminComponent} from "./2-admin/admin.component"

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
