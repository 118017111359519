<mat-tab-group #tabGroup>
    <mat-tab label="Välkommen">
        <ng-template matTabContent>
            hej
        </ng-template>
    </mat-tab>
    <mat-tab label="Foto">
        <ng-template matTabContent>
            <aku-registered></aku-registered>
        </ng-template>
    </mat-tab>
    <mat-tab label="Användare">
        <ng-template matTabContent>
            <aku-user></aku-user>
        </ng-template>
    </mat-tab>
    <mat-tab label="Avtal">
        <ng-template matTabContent>
            <aku-agreement></aku-agreement>
        </ng-template>
    </mat-tab>
</mat-tab-group>